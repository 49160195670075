@import "~flag-icons/css/flag-icons.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


html, body{
  min-height: 100%;
}

.email-multiselect__control--is-focused {
  @apply outline-none ring-2 ring-offset-2 border-yellow-Y100 ring-yellow-Y60 #{!important};
}
.email-multiselect__input{
  @apply ring-0 #{!important};
}
.email-multiselect__multi-value{
  @apply bg-yellow-Y50 #{!important};
}

.country-multiselect__group:first-child {
  @apply border-b border-solid border-brown-B100;
}
